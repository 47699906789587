import React from 'react'

import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication, Configuration } from '@azure/msal-browser'

interface AuthProviderProps {
  config: Configuration
  children: React.ReactNode
}

export const AuthProvider = ({ config, children }: AuthProviderProps) => {
  const msalInstance = new PublicClientApplication(config)
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}
