import React from 'react'

import { Box, useColorModeValue } from '@chakra-ui/react'

interface FixedPositionProps {
  marginX?: number | string
  marginY?: number | string
  paddingX?: number | string
  paddingY?: number | string
  enableHover?: boolean
  onClick?: () => void
  children?: React.ReactNode
  fit?: boolean
}

export const Paper = ({
  marginX = 0,
  marginY = 0,
  paddingX = '20px',
  paddingY = '10px',
  enableHover = false,
  onClick = undefined,
  fit = false,
  children,
}: FixedPositionProps) => {
  const fgColor = useColorModeValue('black', 'white')
  const bgColor = useColorModeValue('white', 'gray.900')
  const hoverBackground = useColorModeValue('blackAlpha.300', 'whiteAlpha.300')

  return (
    <Box
      marginX={marginX}
      marginY={marginY}
      paddingX={paddingX}
      paddingY={paddingY}
      color={fgColor}
      bg={bgColor}
      shadow='lg'
      onClick={onClick}
      minH={fit ? '100%' : undefined}
      _hover={
        enableHover
          ? {
              background: hoverBackground,
              cursor: 'pointer',
            }
          : {}
      }
    >
      {children}
    </Box>
  )
}
