import React from 'react'

import { Box, Table, Tbody, Tr, Td } from '@chakra-ui/react'

interface BuildInfoProps {
  data: Record<string, number | string>
  width?: number
  tableSize?: 'sm' | 'md' | 'lg'
  opacityDefault?: number
  opacityHover?: number
  keyTextColor?: string
  keyBgColor?: string
  defaultTextColor?: string
  defaultBgColor?: string
}

export const BuildInfo = ({
  data,
  width = 400,
  tableSize = 'sm',
  opacityDefault = 0.1,
  opacityHover = 1,
  keyTextColor = 'gray.100',
  keyBgColor = 'gray.500',
  defaultTextColor = 'black',
  defaultBgColor = 'white',
}: BuildInfoProps) => {
  return (
    <Box w={width} opacity={opacityDefault} _hover={{ opacity: opacityHover }}>
      <Table
        variant='simple'
        size={tableSize}
        sx={{
          'table, th, td': {
            width: 'auto',
            overflow: 'auto',
            border: '2px solid transparent',
          },
          'td.key': {
            backgroundColor: keyBgColor,
            color: keyTextColor,
            fontWeight: 'normal',
            textAlign: 'center',
          },
          td: {
            backgroundColor: defaultBgColor,
            color: defaultTextColor,
            fontWeight: 'bold',
            padding: '10px 20px',
          },
        }}
      >
        <Tbody>
          {Object.keys(data).map((key) => {
            return (
              <Tr key={key}>
                <Td className='key'>{key}</Td>
                <Td className='val'>{data[key]}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Box>
  )
}
