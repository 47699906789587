import React from 'react'

import { Routes, Route, useNavigate } from 'react-router-dom'

import {
  FaClipboardList as OrderIcon,
  FaRegListAlt as TerminalListIcon,
  FaTabletAlt as TerminalDetailIcon,
} from 'react-icons/fa'

import { AiFillHome as HomeIcon } from 'react-icons/ai'

import Breadcrumbs, { BreadcrumbSegment } from '../../components/Breadcrumbs'
import Dashboard from '../Dashboard'
import TerminalList from '../TerminalList'
import TerminalDetail from '../TerminalDetail'

import { Error404 } from '../../components/ErrorPages'

const HomeBreadcrumb: BreadcrumbSegment = {
  text: 'Home',
  icon: HomeIcon,
  link: '/',
}

const TerminalsBreadcrumb: BreadcrumbSegment = {
  text: 'Terminals',
  icon: TerminalListIcon,
  link: '/terminals',
}

const TerminalDetailBreadcrumb: BreadcrumbSegment = {
  text: 'Terminal',
  icon: TerminalDetailIcon,
  link: '#',
}

export const Home = () => {
  const navigate = useNavigate()

  return (
    <>
      <Routes>
        <Route path='/'>
          <Route
            index
            element={
              <>
                <Breadcrumbs breadcrumbs={[{ ...HomeBreadcrumb, isCurrentPage: true }]} />
                <Dashboard />
              </>
            }
          />
          <Route
            path='terminals'
            element={
              <>
                <Breadcrumbs breadcrumbs={[HomeBreadcrumb, { ...TerminalsBreadcrumb, isCurrentPage: true }]} />
                <TerminalList onSelectTerminal={(id: string) => navigate(`/terminals/${id}`)} />
              </>
            }
          />
        </Route>
        <Route
          path='terminals/:terminalId'
          element={
            <>
              <Breadcrumbs
                breadcrumbs={[
                  HomeBreadcrumb,
                  TerminalsBreadcrumb,
                  { ...TerminalDetailBreadcrumb, isCurrentPage: true },
                ]}
              />
              <TerminalDetail />
            </>
          }
        />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </>
  )
}
