import React from 'react'

import { Center, Container } from '@chakra-ui/react'

import BackgroundImage from '../../components/BackgroundImage'
import MarkdownRender from '../../components/MarkdownRender'
import FixedPosition from '../../components/FixedPosition'
import BuildInfo from '../../components/BuildInfo'

import background from '../../config/background.jpeg'
import { privacyNotice } from '../../config/config'

const data: Record<string, number | string> = {
  'Version No': process.env.REACT_APP_VERSION_NO || 'UNKNOWN',
  'GIT Commit': process.env.REACT_APP_GIT_COMMIT || 'UNKNOWN',
  'Build Date': process.env.REACT_APP_BUILD_DATE || 'UNKNOWN',
}
const opacity = process.env.NODE_ENV === 'production' ? 0 : 1

export const Landing = () => {
  return (
    <>
      <BackgroundImage imageURL={background} fit='cover'>
        <Center minH='100vh'>
          <Container maxW='container.md'>
            <MarkdownRender content={privacyNotice} />
          </Container>
        </Center>
      </BackgroundImage>
      <FixedPosition right={5} bottom={5}>
        <BuildInfo data={data} opacityDefault={opacity}></BuildInfo>
      </FixedPosition>
    </>
  )
}
