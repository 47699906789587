import React, { ReactNode } from 'react'

import {
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Box,
  Spacer,
  HStack,
  VStack,
  Heading,
  InputGroup,
  Input,
  InputLeftElement,
  Switch,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { TriangleDownIcon, TriangleUpIcon, SearchIcon } from '@chakra-ui/icons'

import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

type DataTableProps<D extends object = {}> = {
  tableHeading?: string
  enablePagination?: boolean
  showPaginationToggle?: boolean
  enableSearch?: boolean
  columns: ColumnDef<D>[]
  data: any
  onRowClick?: (data: D) => void
}

export const DataTable = <D extends {}>({ tableHeading, data, columns, onRowClick }: DataTableProps<D>) => {
  const hoverBackground = useColorModeValue('blackAlpha.300', 'whiteAlpha.300')

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <VStack align='stretch'>
      {tableHeading && (
        <HStack paddingX={6}>
          <Text color='brand.100' fontWeight={600}>
            {_.toUpper(tableHeading)}
          </Text>
          <Spacer flexGrow={2} />
        </HStack>
      )}
      <Table size='md'>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr
              key={row.id}
              transition='0.25s'
              _hover={
                onRowClick && {
                  background: hoverBackground,
                  cursor: 'pointer',
                }
              }
              onClick={() => onRowClick && onRowClick(row.original)}
            >
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </VStack>
  )
}
