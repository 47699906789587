import React from 'react'

import { Center, VStack, Spinner, Text } from '@chakra-ui/react'

interface LoadingProps {
  message?: string
  children?: React.ReactNode
  spinnerSize?: string
  spinnerThickness?: string
  spinnerSpeed?: string
  spinnerVoidColor?: string
  spinnerColor?: string
}

export const Loading = ({
  message,
  children,
  spinnerSize = 'xl',
  spinnerThickness,
  spinnerSpeed,
  spinnerVoidColor,
  spinnerColor,
}: LoadingProps) => {
  return (
    <Center position='absolute' top={0} height='100vh' left={0} right={0}>
      <VStack>
        <Spinner
          thickness={spinnerThickness}
          speed={spinnerSpeed}
          emptyColor={spinnerVoidColor}
          color={spinnerColor}
          size={spinnerSize}
        />
        {message && <Text>{message}</Text>}
        {children && <>{children}</>}
      </VStack>
    </Center>
  )
}
