import React from 'react'

import {
  Center,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

interface EnrolmentViewProps {
  apiURL: string
  authHeader: string
  authKey: string
  qrCode: string
  isOpen: boolean
  onClose: () => void
}

export const EnrolmentView = ({ apiURL, authHeader, authKey, qrCode, isOpen, onClose }: EnrolmentViewProps) => {
  return (
    <Modal isOpen={isOpen} size='xl' onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Device Enrolment Info</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <QrCode data={qrCode} />
          </Center>
          <TableContainer>
            <Table variant='simple' size='sm'>
              <Tbody>
                <Tr>
                  <Td>API URL</Td>
                  <Td>{apiURL}</Td>
                </Tr>
                <Tr>
                  <Td>Auth Header</Td>
                  <Td>{authHeader}</Td>
                </Tr>
                <Tr>
                  <Td>Auth Key</Td>
                  <Td>{authKey}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface QrCodeProps {
  data: string
}

const QrCode = ({ data }: QrCodeProps) => <img src={`data:image/jpeg;base64,${data}`} alt='qr-code for scanning' />
