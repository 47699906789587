import React from 'react'

import {
  Box,
  Center,
  Heading,
  HStack,
  VStack,
  Spacer,
  IconButton,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from '@chakra-ui/react'
import { IoIosHelpCircleOutline as HelpIcon } from 'react-icons/io'

import Paper from '../Paper'

interface KPIProps {
  title: string
  description: string
  minHeight?: number
  enableHover?: boolean
  onClick?: () => void
}

export const KPI = ({ title, description, minHeight, enableHover = false, onClick }: KPIProps) => {
  return (
    <Paper enableHover={enableHover} onClick={onClick}>
      <Center minH={minHeight}>
        <VStack>
          <Heading size='lg' fontWeight={400}>
            {title}
          </Heading>
          <Heading size='sm' fontWeight={300} textAlign='center'>
            {description}
          </Heading>
        </VStack>
      </Center>
    </Paper>
  )
}
