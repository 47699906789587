import React, { ReactNode } from 'react'

import { HStack, Spacer, Text } from '@chakra-ui/react'

import _ from 'lodash'

import Paper from '../Paper'

interface PanelFrameProps {
  title: string
  action?: ReactNode
  children?: ReactNode
  fit?: boolean
}

export const PanelFrame = ({ title, action, children, fit = false }: PanelFrameProps) => {
  return (
    <Paper fit={fit}>
      <HStack mb={1}>
        <Text color='brand.100' fontWeight={600}>
          {_.upperCase(title)}
        </Text>
        <Spacer />
        {action}
      </HStack>
      {children}
    </Paper>
  )
}
