import React from 'react'

import { useColorModeValue } from '@chakra-ui/color-mode'

import { ImSad } from 'react-icons/im'

import { ErrorBase } from './ErrorBase'

export const Error404 = () => {
  const bgColor = useColorModeValue('orange.200', 'orange.300')

  return (
    <ErrorBase
      icon={ImSad}
      code='404'
      text='Not Found'
      detail='The resource requested could not be found on this server'
      backgroundColor={bgColor}
    />
  )
}
