import React, { ReactNode } from 'react'

import { SimpleGrid, Text, List, ListItem } from '@chakra-ui/react'

export interface PanelDataRowProps {
  param?: string
  value?: string | number | ReactNode
  indent?: boolean
}

export const PanelDataRow = ({ param, value, indent = false }: PanelDataRowProps) => {
  return (
    <SimpleGrid columns={2} spacing={5}>
      <List>
        <ListItem>
          <Text marginLeft={indent ? 4 : 0}>{param}</Text>
        </ListItem>
      </List>
      <List>
        <ListItem>
          {typeof value === 'string' || typeof value === 'number' ? <Text fontWeight={600}>{value}</Text> : value}
        </ListItem>
      </List>
    </SimpleGrid>
  )
}
