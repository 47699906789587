import React, { useEffect } from 'react'

import { Box, VStack, Text, useColorModeValue } from '@chakra-ui/react'

import { useQuery } from '@apollo/client'

import { useSearchParams } from 'react-router-dom'

import _ from 'lodash'
import moment from 'moment'

import Paper from '../../components/Paper'
import Loading from '../../components/Loading'
import FixedAlert from '../../components/FixedAlert'
import DataTable, { ColumnDef, CellTitle, CellLeft } from '../../components/DataTable'

import { TERMINAL_LIST } from './queries'

interface Terminal {
  id: string
  name: string
  storeId: string
  companyId: string
  created: string
  updated: string
  lastHeartbeat: string
  status: 'REGISTERED' | 'ACTIVE' | 'DISABLED'
}

interface Result {
  Terminals: {
    count: number
    data: Terminal[]
  }
}

const columns: ColumnDef<Terminal>[] = [
  {
    header: 'ID',
    cell: ({ row }) => <CellTitle input={row.original.id} />,
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: () => (
      <>
        <Text>Company ID</Text>
        <Text>Store ID</Text>
      </>
    ),
    accessorKey: 'companyId',
    cell: ({ row }) => (
      <Box>
        <CellLeft input={row.original.companyId} />
        <CellLeft input={row.original.storeId} />
      </Box>
    ),
  },
  {
    header: () => (
      <>
        <Text>Created</Text>
        <Text>Updated</Text>
      </>
    ),
    accessorKey: 'created',
    cell: ({ row }) => (
      <Box>
        <CellLeft input={moment(row.original.created).format('llll')} />
        <CellLeft input={moment(row.original.updated).isValid() ? moment(row.original.updated).format('llll') : '-'} />
      </Box>
    ),
  },
  {
    header: 'Last Hearbeat',
    cell: ({ row }) => (
      <Box>
        <CellLeft input={moment(row.original.lastHeartbeat).fromNow()} />
      </Box>
    ),
  },
  {
    header: 'Status',
    accessorKey: 'status',
  },
]

interface TerminalListProps {
  onSelectTerminal: (name: string) => void
}

export const TerminalList = ({ onSelectTerminal }: TerminalListProps) => {
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  const { loading, error, data, startPolling, stopPolling } = useQuery<Result>(TERMINAL_LIST, {
    variables: { status },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const hoverBackground = useColorModeValue('blackAlpha.300', 'whiteAlpha.300')
  useEffect(() => {
    startPolling(10000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  useEffect(() => {
    error && console.log(error)
  }, [error])

  if (loading && data === undefined) {
    return <Loading />
  }
  return (
    <>
      <VStack paddingX={5} spacing={5} align='stretch'>
        <Paper>
          <DataTable
            tableHeading={`Terminal List (${data?.Terminals.count})`}
            columns={columns}
            data={data?.Terminals.data}
            onRowClick={(data) => onSelectTerminal(data.id)}
          />
        </Paper>
      </VStack>

      {error && <FixedAlert type='error' title='Error' message='Unable to Retrieve/Refresh Terminal List' />}
    </>
  )
}
