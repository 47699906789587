import React, { ReactNode } from 'react'

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError, RedirectRequest } from '@azure/msal-browser'

interface ApolloAuthProviderProps {
  loginRequest: RedirectRequest
  loginSilentRequest: RedirectRequest
  children: ReactNode
}

export const ApolloAuthProvider = ({ loginRequest, loginSilentRequest, children }: ApolloAuthProviderProps) => {
  const { instance, accounts, inProgress } = useMsal()

  const AsyncTokenLookup = async () => {
    const account = accounts.length > 0 ? accounts[0] : undefined
    if (account && inProgress === 'none') {
      try {
        const result = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account,
        })
        return result.idToken
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return instance.acquireTokenRedirect(loginRequest)
        }
      }
    } else if (!account && inProgress === 'none') {
      return instance.acquireTokenRedirect(loginRequest)
    }
    return null
  }

  const withToken = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await AsyncTokenLookup()

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_API_URL}/query`,
  })

  const client = new ApolloClient({
    link: from([withToken, httpLink]),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
