import { gql } from '@apollo/client'

export const TERMINAL_LIST = gql`
  query Terminals($status: TerminalStatus) {
    Terminals(status: $status) {
      count
      data {
        id
        name
        storeId
        companyId
        created
        updated
        lastHeartbeat
        status
      }
    }
  }
`
