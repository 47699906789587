import React, { ReactNode } from 'react'

import { FormControl, FormLabel, FormHelperText, FormErrorMessage, Input, Button, Select } from '@chakra-ui/react'

import { useController, UseControllerProps, ControllerRenderProps, Path } from 'react-hook-form'

type FieldHelperType<T> = UseControllerProps<T> & {
  isHidden?: boolean
  label: string
  labelInline?: boolean
  isDisabled?: boolean
  helperText?: string
  customError?: string
  widget: (field: ControllerRenderProps<T, Path<T>>) => JSX.Element
}

export function FieldHelper<T>({
  isHidden = false,
  label,
  labelInline,
  isDisabled,
  helperText,
  customError,
  widget,
  ...props
}: FieldHelperType<T>) {
  const { field, fieldState } = useController<T>(props)
  const error = fieldState.error ? true : false
  const errorMessage =
    fieldState.error?.type === 'required'
      ? `${label} is required`
      : fieldState.error?.type === 'minLength'
      ? `${label} must have a minimum length of ${props.rules?.minLength}`
      : fieldState.error?.type === 'maxLength'
      ? `${label} must have a maximum length of ${props.rules?.maxLength}`
      : fieldState.error?.type === 'min'
      ? `${label} must be more than ${props.rules?.min}`
      : fieldState.error?.type === 'max'
      ? `${label} must be less than ${props.rules?.max}`
      : fieldState.error?.type === 'pattern'
      ? `${label} ${customError}`
      : fieldState.error?.type === 'validate'
      ? `${label} ${customError}`
      : undefined

  return (
    <FormControl
      display={isHidden ? 'none' : labelInline ? 'flex' : undefined}
      mb={labelInline ? 3 : undefined}
      isInvalid={error}
      isDisabled={isDisabled}
      isRequired={props.rules?.required ? true : false}
    >
      <FormLabel>{label}</FormLabel>
      {widget(field)}
      {!error && <FormHelperText>{helperText}&zwnj;</FormHelperText>}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  )
}
