import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const colors = {
  brand: {
    100: '#f70100',
    200: '#2563eb',
    700: '#2a69ac',
    500: '#d7d7d7',
    501: '#1e1e1e',
  },
}

const components = {
  Button: {
    baseStyle: {
      _focus: { boxShadow: 'none' },
    },
  },
}

const styles = {
  global: (props: any) => ({
    'html, body': {
      background: mode('gray.100', '#121212')(props),
    },
  }),
}

export const theme = extendTheme({
  config,
  colors,
  components,
  styles,
})
