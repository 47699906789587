import React from 'react'

import {
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'

import { useForm } from 'react-hook-form'

import { FieldHelper } from '../../components/ReactHookFormHelpers'
import FixedAlert from '../../components/FixedAlert'

import { Terminal } from './TerminalDetail'

export type TerminalSettings = {
  name: string
  companyId: string
  storeId: string
  syncInterval: number
  lockoutInterval: number
}

type TerminalSettingsModalProps = {
  onSubmit: (data: TerminalSettings) => void
  onClose: () => void
  data: Terminal
  isSubmitting: boolean
}

export const TerminalSettingsModal = ({ onSubmit, onClose, data, isSubmitting }: TerminalSettingsModalProps) => {
  const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure()

  const { handleSubmit, register, control } = useForm<TerminalSettings>({
    mode: 'all',
    defaultValues: data as TerminalSettings,
  })

  return (
    <>
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent minW='40em'>
            <ModalHeader>Edit Terminal Settings</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FieldHelper<TerminalSettings>
                control={control}
                label='Terminal Name'
                name='name'
                rules={{ required: true, maxLength: 20, minLength: 5, pattern: /^[^\s]+.*[^\s]+$/ }}
                widget={(field) => <Input {...register(field.name)} />}
              />
              <FieldHelper<TerminalSettings>
                control={control}
                label='Company ID'
                name='companyId'
                rules={{ required: true, maxLength: 20, minLength: 2, pattern: /^[^\s]+.*[^\s]+$/ }}
                customError='must not include spaces at the start or end'
                widget={(field) => <Input {...register(field.name)} />}
              />
              <FieldHelper<TerminalSettings>
                control={control}
                label='Store ID'
                name='storeId'
                rules={{ required: true, maxLength: 20, minLength: 2, pattern: /^[^\s]+.*[^\s]+$/ }}
                customError='must not include spaces at the start or end'
                widget={(field) => <Input {...register(field.name)} />}
              />
              <FieldHelper<TerminalSettings>
                control={control}
                label='Sync Interval'
                name='syncInterval'
                rules={{ required: true, min: 5, max: 10080 }}
                widget={(field) => <Input type='number' {...register(field.name)} />}
                helperText='Please enter a value in minutes. Min 5, Max 10,080 (7 days)'
              />
              <FieldHelper<TerminalSettings>
                control={control}
                label='Lockout Interval'
                name='lockoutInterval'
                rules={{ required: true, min: 5, max: 10080 }}
                widget={(field) => <Input type='number' {...register(field.name)} />}
                helperText='Please enter a value in minutes. Min 5, Max 10,080 (7 days)'
              />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} isLoading={isSubmitting} type='submit'>
                Confirm
              </Button>
              <Button variant='ghost' onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
        <FixedAlert
          type='error'
          title='Error'
          message='Unable to Save Terminal Settings'
          isOpen={isErrorOpen}
          onClose={onErrorClose}
        />
      </Modal>
    </>
  )
}
