import React from 'react'

import { Center, VStack, Text, Icon } from '@chakra-ui/react'

import { IconType } from 'react-icons/lib'
import { BsFillExclamationOctagonFill } from 'react-icons/bs'

interface ErrorBaseProps {
  icon?: IconType
  code?: string
  text?: string
  detail?: string
  color?: string
  backgroundColor?: string
}

export const ErrorBase = ({
  icon = BsFillExclamationOctagonFill,
  code = 'Oops!',
  text = 'Something went wrong.',
  detail,
  color,
  backgroundColor = 'gray.500',
}: ErrorBaseProps) => {
  return (
    <Center height='100vh' width='100vw' bg={backgroundColor} color={color}>
      <VStack>
        <Icon as={icon} w={20} h={20} />
        <Text fontSize='6xl' fontWeight={600}>
          {code}
        </Text>
        <Text fontSize='3xl'>{text}</Text>
        <Text>{detail}</Text>
      </VStack>
    </Center>
  )
}
