import React, { ReactNode } from 'react'

import { Box } from '@chakra-ui/react'

interface BackgroundImageProps {
  imageURL: string
  fit?: 'auto' | 'cover' | 'contain'
  padding?: number | string
  children: ReactNode
}

export const BackgroundImage = ({ imageURL, fit = 'cover', padding = 0, children }: BackgroundImageProps) => {
  return (
    <Box
      position='fixed'
      backgroundImage={imageURL}
      backgroundSize={fit}
      backgroundRepeat='no-repeat'
      backgroundPosition='center'
      left={padding}
      top={padding}
      right={padding}
      bottom={padding}
      zIndex={-100}
    >
      {children}
    </Box>
  )
}
