import React from 'react'

import { Box, Spacer, Text } from '@chakra-ui/react'

export const CellLeft = ({ input }: { input: string | number }) => <Box>{input}</Box>

export const CellRight = ({ input }: { input: string | number }) => <Box textAlign='right'>{input}</Box>

export const CellTitle = ({ input }: { input: string | number }) => (
  <Text fontSize='12px' fontWeight={500} casing='uppercase'>
    {input}
  </Text>
)

export const Title = ({ input, fontWeight = 500, ml = 0 }: { input: string; fontWeight?: number; ml?: number }) => (
  <Text fontSize='12px' fontWeight={fontWeight} ml={ml} casing='uppercase'>
    {input}
  </Text>
)

export const HeaderRight = ({ input }: { input: string | number }) => (
  <>
    <Spacer />
    <Box>{input}</Box>
  </>
)
