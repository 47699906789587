import React from 'react'

import { Box, Alert, AlertIcon, AlertTitle, AlertDescription, Container, CloseButton } from '@chakra-ui/react'

interface FixedAlertProps {
  type: 'info' | 'warning' | 'success' | 'error'
  title: string
  message: string
  isOpen?: boolean
  onClose?: () => void
}

export const FixedAlert = ({ type, title, message, isOpen = true, onClose }: FixedAlertProps) => {
  return (
    <Box bottom={0} left={0} right={0} position='fixed' visibility={isOpen ? undefined : 'hidden'}>
      <Container>
        <Alert status={type}>
          <AlertIcon />
          <Box width='100%'>
            <AlertTitle mr={2}>{title}</AlertTitle>
            <AlertDescription>{message}</AlertDescription>
          </Box>
          {onClose && (
            <CloseButton
              alignSelf='flex-start'
              position='relative'
              right={-1}
              top={-1}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClose()
              }}
            />
          )}
        </Alert>
      </Container>
    </Box>
  )
}
