import React from 'react'

import { Link } from 'react-router-dom'

import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Text, Icon } from '@chakra-ui/react'

import { IconType } from 'react-icons/lib'

export interface BreadcrumbSegment {
  text: string
  icon: IconType
  link: string
  isCurrentPage?: boolean
}

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbSegment[]
}

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  return (
    <Box p={5}>
      <Breadcrumb>
        {breadcrumbs.map((breadcrumb) => (
          <BreadcrumbItem key={breadcrumb.text}>
            <BreadcrumbLink as={Link} to={breadcrumb.link} isCurrentPage={breadcrumb.isCurrentPage}>
              <HStack>
                <Icon as={breadcrumb.icon} />
                <Text>{breadcrumb.text}</Text>
              </HStack>
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Box>
  )
}
