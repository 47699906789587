import { gql } from '@apollo/client'

export const TERMINAL_STATS = gql`
  query TerminalStats {
    TerminalStats {
      noDevices
      noActive
      noRegistered
      noDisabled
    }
  }
`
export const HEARTBEAT_STATS = gql`
  query HeartbeatStats {
    HeartbeatStats {
      heartbeatsByDay {
        date
        count
      }
    }
  }
`

export const API_ENDPOINT_INFO = gql`
  query Api {
    ApiEndpoint {
      url
      authKey
      authHeader
      qrCode
    }
  }
`

export const GET_TERMINAL_SUMMARY = gql`
  query {
    Terminals {
      data {
        id
        name
        location {
          latitude
          longitude
          altitude
        }
      }
    }
  }
`
