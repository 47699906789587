import { gql } from '@apollo/client'

export const GET_TERMINAL = gql`
  query ($id: ID!) {
    Terminal(id: $id) {
      id
      name
      storeId
      companyId
      syncInterval
      lockoutInterval
      lastHeartbeat
      macAddress
      location {
        latitude
        longitude
        altitude
      }
      lastLocation
      status
      created
      updated
    }
  }
`
