import React, { PureComponent } from 'react'

import { VStack, HStack, Center, Text, Box, Spinner } from '@chakra-ui/react'

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import _ from 'lodash'

import Paper from '../Paper'

type BarChartSimpleProps = {
  data?: {
    [key: string]: string | Date | number
  }[]
  xAxis: string
  value: string
  height?: string | number
  title?: string
  loading?: boolean
}

export const BarChartSimple = ({
  data,
  xAxis,
  value,
  height = '250px',
  title,
  loading = false,
}: BarChartSimpleProps) => {
  return (
    <Paper>
      <VStack align='stretch' h={height}>
        {title && (
          <Text color='brand.100' fontWeight={600}>
            {_.toUpper(title)}
          </Text>
        )}
        <Box flexGrow={2}>
          {data ? (
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart
                data={data}
                margin={{
                  top: 10,
                  right: 10,
                  left: 10,
                  bottom: 10,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey={xAxis} />
                <YAxis />
                <Tooltip />
                <Bar dataKey={value} fill='#8884d8' />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Center h='100%'>{loading ? <Spinner size='sm' /> : <Text>No Data</Text>}</Center>
          )}
        </Box>
      </VStack>
    </Paper>
  )
}
