import React from 'react'

import { Box } from '@chakra-ui/react'

interface FixedPositionProps {
  children?: React.ReactNode
  top?: string | number
  bottom?: string | number
  left?: string | number
  right?: string | number
  zIndex?: number
}

export const FixedPosition = ({ children, top, bottom, left, right, zIndex }: FixedPositionProps) => {
  return (
    <Box top={top} bottom={bottom} left={left} right={right} position='fixed' zIndex={zIndex}>
      {children}
    </Box>
  )
}
