import * as React from 'react'
import { useColorMode, useColorModeValue, IconButton, IconButtonProps } from '@chakra-ui/react'
import { HiMoon as Moon, HiSun as Sun } from 'react-icons/hi'

type ColorModeSwitcherProps = Omit<IconButtonProps, 'aria-label'>

export const ColorModeSwitcher = (props: ColorModeSwitcherProps) => {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue('dark', 'light')
  const Icon = useColorModeValue(Moon, Sun)

  return (
    <IconButton
      size='md'
      fontSize='lg'
      borderRadius={999}
      variant='ghost'
      color='current'
      onClick={toggleColorMode}
      icon={<Icon />}
      aria-label={`Switch to ${text} mode`}
      {...props}
    />
  )
}
