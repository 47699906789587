import React from 'react'

import { Box, Flex, Image, useColorModeValue, HStack, Button, IconButton, Heading, Text } from '@chakra-ui/react'
import { MdOutlineLogin as Login, MdOutlineLogout as Logout } from 'react-icons/md'
import { HiUser as User } from 'react-icons/hi'

import ColorModeSwitcher from '../ColorModeSwitcher'

interface NavbarProps {
  appName: string
  userName?: string
  logoLight: string
  logoDark: string
  onSignIn?: () => void
  onSignOut?: () => void
  isLoggedIn: boolean
}

export const Navbar = ({ appName, userName, logoLight, logoDark, onSignIn, onSignOut, isLoggedIn }: NavbarProps) => {
  const navbarBg = useColorModeValue('white', 'black')
  const userBg = useColorModeValue('gray.100', 'gray.800')
  const logo = useColorModeValue(logoLight, logoDark)

  return (
    <>
      <Box bg={navbarBg} w='full' px={{ base: 2, sm: 4 }} py={3} shadow='md'>
        <Flex alignItems='center' justifyContent='space-between' mx='auto'>
          <HStack spacing={3}>
            <Image h={5} src={logo} />
            <Heading fontSize='22px' fontWeight='600'>
              {appName}
            </Heading>
          </HStack>
          <HStack display='flex' alignItems='center' spacing={1}>
            {isLoggedIn ? (
              <>
                <HStack bg={userBg} paddingX={2} paddingY={1}>
                  <User />
                  <Text fontSize='sm'>{userName}</Text>s
                </HStack>
                &nbsp;
                <IconButton
                  size='md'
                  fontSize='lg'
                  borderRadius={999}
                  variant='ghost'
                  color='current'
                  icon={<Logout />}
                  aria-label={`sign out`}
                  onClick={onSignOut}
                />
              </>
            ) : (
              <Button size='sm' bg='brand.100' color='white' onClick={onSignIn}>
                <Login />
                &nbsp; Sign In
              </Button>
            )}
            <ColorModeSwitcher />
          </HStack>
        </Flex>
      </Box>
    </>
  )
}
