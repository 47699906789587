import React from 'react'

import { Center } from '@chakra-ui/react'

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'

import Navbar from './components/Navbar'
import Loading from './components/Loading'
import Landing from './containers/Landing'
import Home from './containers/Home'

import { appName } from './config/config'
import logoLight from './config/logo-light.svg'
import logoDark from './config/logo-dark.svg'

function App() {
  const { instance, accounts, inProgress } = useMsal()
  const account = accounts.length > 0 ? accounts[0] : undefined

  if (inProgress === 'login' || inProgress === 'logout')
    return (
      <Center height='100vh' width='100vw'>
        <Loading />
      </Center>
    )

  return (
    <>
      <Navbar
        appName={appName}
        logoLight={logoLight}
        logoDark={logoDark}
        isLoggedIn={account !== undefined}
        userName={account?.name}
        onSignIn={() => instance.loginRedirect()}
        onSignOut={() => instance.logoutRedirect()}
      />
      <UnauthenticatedTemplate>
        <Landing />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Home />
      </AuthenticatedTemplate>
    </>
  )
}

export default App
