import { gql } from '@apollo/client'

export const SAVE_TERMINAL_DETAILS = gql`
  mutation ($id: ID!, $data: TerminalUpdate!) {
    TerminalUpdate(id: $id, data: $data) {
      id
    }
  }
`

export const TERMINAL_ACTIVATE = gql`
  mutation ($terminalId: ID!) {
    TerminalActivate(id: $terminalId) {
      status
    }
  }
`

export const TERMINAL_SET_STATUS = gql`
  mutation ($terminalId: ID!, $status: TerminalStatus!) {
    TerminalSetStatus(id: $terminalId, status: $status) {
      status
    }
  }
`
