import React, { useEffect, useState } from 'react'

import { VStack, SimpleGrid, Button, Text, useDisclosure } from '@chakra-ui/react'

import Loading from '../../components/Loading'
import KPI from '../../components/KPI'
import BarChartSimple from '../../components/BarChart'
import SimpleMap, { Marker } from '../../components/Map'

import { useQuery } from '@apollo/client'

import { useNavigate } from 'react-router-dom'

import numeral from 'numeral'

import { TERMINAL_STATS, HEARTBEAT_STATS, API_ENDPOINT_INFO, GET_TERMINAL_SUMMARY } from './queries'
import moment from 'moment'
import FixedPosition from '../../components/FixedPosition'
import EnrolmentView from '../../components/EnrolmentView'

interface TerminalStatsResult {
  TerminalStats: {
    noDevices: number
    noActive: number
    noRegistered: number
    noDisabled: number
  }
}

interface HeartbeatStatsResult {
  HeartbeatStats: {
    heartbeatsByDay: { date: string; count: number }[]
  }
}

interface ApiEndpointInfo {
  ApiEndpoint: {
    url: string
    authHeader: string
    authKey: string
    qrCode: string
  }
}

interface Terminal {
  id: string
  name?: string
  location?: {
    latitude: number
    longitude: number
    altitude?: number
  }
}

interface TerminalListResult {
  Terminals: {
    count: number
    data: Terminal[]
  }
}

export const Dashboard = () => {
  const [markers, setMarkers] = useState<Marker[]>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const navigate = useNavigate()

  const terminalStatsQuery = useQuery<TerminalStatsResult>(TERMINAL_STATS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const heartbeatStatsQuery = useQuery<HeartbeatStatsResult>(HEARTBEAT_STATS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const terminalListQuery = useQuery<TerminalListResult>(GET_TERMINAL_SUMMARY, {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const terminalsWithLocation = data.Terminals.data.filter((terminal) => terminal.location)
      const terminalMarkers: Marker[] = terminalsWithLocation.map((terminal) => ({
        latitude: terminal.location?.latitude!,
        longitude: terminal.location?.longitude!,
        popup: (
          <Text>
            {terminal.id}
            <br />
            {terminal.name}
          </Text>
        ),
      }))
      setMarkers(terminalMarkers)
    },
  })

  const apiEndpointQuery = useQuery<ApiEndpointInfo>(API_ENDPOINT_INFO, {
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    terminalStatsQuery.startPolling(10000)
    heartbeatStatsQuery.startPolling(60000)
    return () => {
      terminalStatsQuery.stopPolling()
      heartbeatStatsQuery.stopPolling()
    }
  }, [heartbeatStatsQuery, terminalStatsQuery])

  useEffect(() => {
    terminalStatsQuery.error && console.log(terminalStatsQuery.error)
  }, [terminalStatsQuery.error])

  useEffect(() => {
    heartbeatStatsQuery.error && console.log(heartbeatStatsQuery.error)
  }, [heartbeatStatsQuery.error])

  if (
    terminalStatsQuery.loading &&
    heartbeatStatsQuery.loading &&
    terminalStatsQuery.data === undefined &&
    heartbeatStatsQuery.data === undefined
  ) {
    return <Loading />
  }

  return (
    <>
      <VStack paddingX={5} spacing={5} align='stretch'>
        <SimpleGrid columns={4} spacing={5}>
          <KPI
            title={numeral(terminalStatsQuery.data?.TerminalStats.noDevices).format('0,0')}
            description='Total Terminals'
            enableHover
            onClick={() => navigate('/terminals')}
          />
          <KPI
            title={numeral(terminalStatsQuery.data?.TerminalStats.noActive).format('0,0')}
            description='Active Terminals'
            enableHover
            onClick={() => navigate('/terminals?status=ACTIVE')}
          />
          <KPI
            title={numeral(terminalStatsQuery.data?.TerminalStats.noRegistered).format('0,0')}
            description='Registered Terminals'
            enableHover
            onClick={() => navigate('/terminals?status=REGISTERED')}
          />
          <KPI
            title={numeral(terminalStatsQuery.data?.TerminalStats.noDisabled).format('0,0')}
            description='Disabled Terminals'
            enableHover
            onClick={() => navigate('/terminals?status=DISABLED')}
          />
        </SimpleGrid>
        <BarChartSimple
          title='Heartbeat Stats'
          data={heartbeatStatsQuery.data?.HeartbeatStats.heartbeatsByDay.map((item) => ({
            date: moment(item.date).format('DD-MMM'),
            count: item.count,
          }))}
          xAxis='date'
          value='count'
          loading={heartbeatStatsQuery.loading}
        />
        <SimpleMap markers={markers} />
      </VStack>
      {apiEndpointQuery.data && (
        <>
          <FixedPosition right={10} bottom={10} zIndex={999}>
            <Button colorScheme='teal' size='lg' onClick={onOpen}>
              Enrol Device
            </Button>
          </FixedPosition>
          <EnrolmentView
            isOpen={isOpen}
            onClose={onClose}
            apiURL={apiEndpointQuery.data?.ApiEndpoint.url}
            authHeader={apiEndpointQuery.data?.ApiEndpoint.authHeader}
            authKey={apiEndpointQuery.data?.ApiEndpoint.authKey}
            qrCode={apiEndpointQuery.data?.ApiEndpoint.qrCode}
          />
        </>
      )}
    </>
  )
}
