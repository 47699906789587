import React from 'react'

import { Tooltip, Icon, IconButton } from '@chakra-ui/react'
import { IconType } from 'react-icons/lib'

interface IconButtonProps {
  icon: IconType
  tooltip: string
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  isDisabled?: boolean
  isLoading?: boolean
  ariaLabel?: string
}

export const RoundIconButton = ({ icon, tooltip, onClick, isDisabled, isLoading, ariaLabel }: IconButtonProps) => {
  return (
    <Tooltip label={tooltip}>
      <IconButton
        size='md'
        fontSize='lg'
        borderRadius={999}
        variant='ghost'
        color='current'
        onClick={onClick}
        isDisabled={isDisabled}
        isLoading={isLoading}
        icon={<Icon as={icon} />}
        aria-label={ariaLabel || ''}
      />
    </Tooltip>
  )
}
