import React, { ReactNode } from 'react'

import { Center } from '@chakra-ui/react'

import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L, { LatLngExpression } from 'leaflet'

import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

import Paper from '../Paper'

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

export interface SimpleMarker {
  latitude: number
  longitude: number
  popup: ReactNode
}

interface MapProps {
  height?: number | string
  position?: LatLngExpression
  zoom?: number
  markers?: SimpleMarker[]
}

export const SimpleMap = ({ position = [49.843, 9.75], zoom = 4, markers = undefined, height = '500px' }: MapProps) => {
  if (!markers)
    return (
      <Paper paddingX='20px' paddingY='20px'>
        <Center h={height}>No Location Data</Center>
      </Paper>
    )

  return (
    <Paper paddingX='20px' paddingY='20px'>
      <MapContainer center={position} zoom={zoom} scrollWheelZoom={false} style={{ height }}>
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((marker, id) => (
          <Marker key={id} position={[marker.latitude, marker.longitude]}>
            <Popup>{marker.popup}</Popup>
          </Marker>
        ))}
      </MapContainer>
    </Paper>
  )
}
