import React from 'react'

import { useDisclosure, Text, VStack, Grid, GridItem } from '@chakra-ui/react'

import { useQuery, useMutation } from '@apollo/client'

import { useParams } from 'react-router-dom'

import { FiEdit as EditIcon } from 'react-icons/fi'
import {
  BsArrowUpCircle as ActivateIcon,
  BsPlayCircle as EnableIcon,
  BsPauseCircle as DisableIcon,
} from 'react-icons/bs'

import moment from 'moment'

import SimpleMap, { Marker } from '../../components/Map'
import RoundIconButton from '../../components/RoundIconButton'
import Loading from '../../components/Loading'
import FixedAlert from '../../components/FixedAlert'
import PanelDataView from '../../components/PanelDataView'
import MutationButton from '../../components/MutationButton'
import DataTable, { ColumnDef, CellTitle, CellLeft } from '../../components/DataTable'

import { GET_TERMINAL } from './queries'
import { SAVE_TERMINAL_DETAILS, TERMINAL_ACTIVATE, TERMINAL_SET_STATUS } from './mutations'
import { TerminalSettingsModal, TerminalSettings } from './TerminalSettingsModal'

export type Terminal = {
  id: string
  name: string
  storeId: string
  companyId: string
  syncInterval: number
  lockoutInterval: number
  lastHeartbeat: string
  macAddress: string
  location?: {
    latitude: number
    longitude: number
    altitude?: number
  }
  lastLocation: string
  status: 'REGISTERED' | 'ACTIVE' | 'DISABLED'
  created: string
  updated: string
}

type Result = {
  Terminal: Terminal
}

export const TerminalDetail = () => {
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure()
  const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure()
  const params = useParams()
  const id = params.terminalId || ''

  const { loading, error, data } = useQuery<Result>(GET_TERMINAL, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      console.log(error)
      onErrorOpen()
    },
  })

  const [mutateFunction, { loading: isEditSaving }] = useMutation(SAVE_TERMINAL_DETAILS, {
    refetchQueries: [{ query: GET_TERMINAL, variables: { id } }],
  })

  if (loading && data === undefined) {
    return <Loading />
  }

  function onEditSubmit(values: TerminalSettings) {
    mutateFunction({
      variables: {
        id: id,
        data: {
          name: values.name,
          companyId: values.companyId,
          storeId: values.storeId,
          syncInterval: values.syncInterval,
          lockoutInterval: values.lockoutInterval,
        },
      },
      onCompleted: () => {
        onEditClose()
      },
      onError: (e) => {
        console.error(e)
        onErrorOpen()
      },
    })
  }

  const terminal = data?.Terminal
  const panel1Rows = [
    { param: 'Terminal ID', value: terminal?.id },
    { param: 'Name', value: terminal?.name },
    { param: 'Store ID', value: terminal?.storeId || '-' },
    { param: 'Company ID', value: terminal?.companyId || '-' },
    { param: 'Sync Interval', value: terminal?.syncInterval },
    { param: 'Lockout Interval', value: terminal?.lockoutInterval },
  ]
  const panel2Rows = [
    { param: 'Terminal Status', value: terminal?.status },
    { param: 'Last Heartbeat', value: moment(terminal?.lastHeartbeat).format('lll') },
    { param: 'MAC Address', value: terminal?.macAddress || '-' },
    {
      param: 'Location',
      value: terminal?.location ? `Lat: ${terminal?.location?.latitude} Long: ${terminal?.location?.longitude}` : '-',
    },
    {
      param: 'Location Updated',
      value: terminal?.lastLocation ? moment(terminal?.lastLocation).format('lll') : '-',
    },
    { param: 'Record Created', value: moment(terminal?.created).format('lll') },
    { param: 'Record Last Updated', value: terminal?.updated ? moment(terminal?.updated).format('lll') : '-' },
  ]

  const marker: Marker | undefined = terminal?.location
    ? {
        latitude: terminal.location.latitude,
        longitude: terminal.location.longitude,
        popup: (
          <Text>
            {terminal.id}
            <br />
            {terminal.name}
          </Text>
        ),
      }
    : undefined

  return (
    <>
      <VStack paddingX={5} spacing={5} align='stretch'>
        <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(2, 1fr)' gap={4}>
          <GridItem colSpan={1}>
            <PanelDataView
              title='Terminal Settings'
              rows={panel1Rows}
              action={
                <>
                  {terminal && (
                    <RoundIconButton icon={EditIcon} tooltip='Edit Terminal Settings' onClick={onEditOpen} />
                  )}
                  {terminal?.status === 'REGISTERED' && (
                    <MutationButton
                      icon={ActivateIcon}
                      tooltip='Activate Terminal'
                      mutation={TERMINAL_ACTIVATE}
                      variables={{ terminalId: terminal.id }}
                      refetch={GET_TERMINAL}
                      refetchVariables={{ id: terminal.id }}
                    />
                  )}
                  {terminal?.status === 'ACTIVE' && (
                    <MutationButton
                      icon={DisableIcon}
                      tooltip='Disable Terminal'
                      mutation={TERMINAL_SET_STATUS}
                      variables={{ terminalId: terminal.id, status: 'DISABLED' }}
                      refetch={GET_TERMINAL}
                      refetchVariables={{ id: terminal.id }}
                    />
                  )}
                  {terminal?.status === 'DISABLED' && (
                    <MutationButton
                      icon={EnableIcon}
                      tooltip='Enable Terminal'
                      mutation={TERMINAL_SET_STATUS}
                      variables={{ terminalId: terminal.id, status: 'ACTIVE' }}
                      refetch={GET_TERMINAL}
                      refetchVariables={{ id: terminal.id }}
                    />
                  )}
                </>
              }
              fit
            />
          </GridItem>
          <GridItem rowSpan={2} colSpan={1}>
            <SimpleMap markers={marker && [marker]} />
          </GridItem>
          <GridItem colSpan={1}>
            <PanelDataView title='Terminal Heartbeat Info' rows={panel2Rows} fit />
          </GridItem>
        </Grid>
      </VStack>

      {isEditOpen && (
        <TerminalSettingsModal
          onSubmit={onEditSubmit}
          onClose={onEditClose}
          data={terminal!}
          isSubmitting={isEditSaving}
        />
      )}

      <FixedAlert
        type='error'
        title='Error'
        message='Unable to Retrieve Terminal Detail'
        isOpen={isErrorOpen}
        onClose={onErrorClose}
      />
    </>
  )
}
