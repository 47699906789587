import React from 'react'
import { createRoot } from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'

import { msalConfig, loginRequest, loginSilentRequest } from './config/authConfig'

import AuthProvider from './components/AuthProvider'
import ApolloAuthProvider from './components/ApolloAuthProvider'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { theme } from './config/theme'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <AuthProvider config={msalConfig}>
          <ApolloAuthProvider loginRequest={loginRequest} loginSilentRequest={loginSilentRequest}>
            <App />
          </ApolloAuthProvider>
        </AuthProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
