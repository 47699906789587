import React from 'react'

import { useDisclosure } from '@chakra-ui/react'

import { DocumentNode, useMutation } from '@apollo/client'

import { IconType } from 'react-icons/lib'

import FixedAlert from '../FixedAlert'
import RoundIconButton from '../RoundIconButton'

type MutationButtonProps = {
  icon: IconType
  tooltip: string
  mutation: DocumentNode
  variables?: Object
  refetch?: DocumentNode
  refetchVariables?: Object
  errorMessage?: string
}

export const MutationButton = ({
  icon,
  tooltip,
  mutation,
  variables = {},
  refetch,
  refetchVariables = {},
  errorMessage = `${tooltip} action failed`,
}: MutationButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [mutateFunction, { loading, error }] = useMutation(
    mutation,
    refetch ? { refetchQueries: [{ query: refetch, variables: refetchVariables }] } : {}
  )

  const onClick: React.MouseEventHandler<HTMLButtonElement> | undefined = (e) => {
    e.preventDefault()
    e.stopPropagation()
    mutateFunction({
      variables,
      onError: (error) => {
        onOpen()
        console.log(error)
      },
    })
  }

  return (
    <>
      <RoundIconButton icon={icon} tooltip={tooltip} onClick={onClick} isLoading={loading} isDisabled={isOpen} />
      {<FixedAlert type='error' title='Error' message={errorMessage} isOpen={isOpen} onClose={onClose} />}
    </>
  )
}
