import React from 'react'

import { Box, useColorModeValue } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'

interface MarkdownRenderProps {
  content: string
  padding?: string
  textColor?: string
  backgroundColor?: string
  textColorCode?: string
  backgroundColorCode?: string
}

export const MarkdownRender = ({
  content,
  padding = '20px 50px',
  textColor,
  backgroundColor,
  textColorCode,
  backgroundColorCode,
}: MarkdownRenderProps) => {
  const defaultFgColor = useColorModeValue('black', 'white')
  const defaultBgColor = useColorModeValue('white', 'gray.900')
  const defaultFgColorCode = useColorModeValue('black', 'white')
  const defaultBgColorCode = useColorModeValue('gray.200', 'gray.700')
  const fgColor = textColor ?? defaultFgColor
  const bgColor = backgroundColor ?? defaultBgColor
  const fgColorCode = textColorCode ?? defaultFgColorCode
  const bgColorCode = backgroundColorCode ?? defaultBgColorCode

  return (
    <Box
      padding={padding}
      color={fgColor}
      bg={bgColor}
      sx={{
        h1: { fontSize: '1.6em', fontWeight: '600', marginBottom: '0.5em' },
        h2: { fontSize: '1.4em', fontWeight: '600' },
        h3: { fontSize: '1.2em', fontWeight: '600' },
        p: { marginBottom: '15px' },
        pre: {
          padding: '20px 0',
          backgroundColor: bgColorCode,
          marginBottom: '15px',
        },
        code: { color: fgColorCode, fontSize: '0.8em' },
        a: {
          color: 'blue.500',
          textDecoration: 'underline',
        },
      }}
    >
      <ReactMarkdown>{content}</ReactMarkdown>
    </Box>
  )
}
