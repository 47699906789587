import React, { ReactNode } from 'react'

import { Center } from '@chakra-ui/react'

import PanelFrame from '../PanelFrame'

import { PanelDataRow, PanelDataRowProps } from './PanelDataRow'

interface PanelDataViewProps {
  title: string
  action?: ReactNode
  rows?: PanelDataRowProps[]
  emptyMessage?: string
  fit?: boolean
}

export const PanelDataView = ({ title, action, rows, emptyMessage = 'No Data', fit = false }: PanelDataViewProps) => {
  if (!rows) {
    return (
      <PanelFrame title={title} action={action} fit={fit}>
        <Center minH={100}>{emptyMessage}</Center>
      </PanelFrame>
    )
  }
  return (
    <PanelFrame title={title} action={action} fit={fit}>
      {rows.map((row) => (
        <PanelDataRow key={row.param} param={row.param} value={row.value} indent={row.indent} />
      ))}
    </PanelFrame>
  )
}
